import React from 'react'
import Navbarinner from "./Navbarinner"

const NavbarAlt = (props) => (
        <section class="hero xmas-bg is-medium is-transparent">

            <div classname="hero-overlay">

                    <div class="hero-head">
                        <nav role="navigation is-transparent" 
                        aria-label="main-navigation" 
                        className={props.sticky ? 'navbar is-fixed-top has-shadow' : 'navbar is-primary'}>
                            <Navbarinner />
                        </nav> 
                    </div>

                    <div class="hero-body">
                        <div class="container">
                            <div class="columns is-vcentered">
                                <div class="is-paddingless column has-text-centered">
                                    <p className="subtitle is-size-5 is-uppercase has-text-weight-bold has-text-white">LIVE FROM THE NORTH POLE...</p>
                                    <p class="title is-size-1 is-size-3-touch has-text-weight-bold has-text-white">It’s Santa!</p>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>

        </section>
    )

export default NavbarAlt