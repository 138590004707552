import React from 'react'
import Form from "../Form/FormRecorded"

import Logo from "../Logo/LogoAlt"

const ModalRecorded = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      modalActiveClass: '',
    }
  }

  toggleModal = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
                modalActiveClass: 'is-active',
            })
          : this.setState({
                modalActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <div>
        <div class={`modal ${this.state.modalActiveClass}`}>
          <div 
            role = "button"
            class="modal-background"
            onClick={() => this.toggleModal()}
            onKeyDown={() => this.toggleModal()}
            tabIndex={0}
          ></div>
          <div class="modal-content">
            <div class="column">
              <div class="card">
                <div class="card-content">
                    <div className="card-media is-centered has-text-centered">
                        <Logo />
                    </div>
                    <p className="subtitle is-size-4 has-text-centered" style={{ maxWidth: "550px", margin: "0 auto 20px" }}>Fill out our questionnaire so that all messages are personalized just for you and your <span className="alt-title">family</span>.</p>
                    <div class="content has-text-black">
                        <Form />
                    </div>
                </div>
                </div>
            </div>
          </div>
          <button 
            class="modal-close is-large" 
            aria-label="close"
            onClick={() => this.toggleModal()}
          >
          </button>
        </div>
        <span className="button is-primary is-large is-size-4-mobile" onClick={() => this.toggleModal()}>Book Now</span>
      </div>
    )
  }
}

export default ModalRecorded