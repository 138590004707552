import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "./Packages.scss"

import Virtual from "../Checkout/Virtual"
import Recorded from "../Checkout/Recorded"

import ModalVirtual from "../Modal/ModalVirtual"
import ModalRecorded from "../Modal/ModalRecorded"


const Packages = () => {
    const data = useStaticQuery(graphql`
    query {
        packages1: file(relativePath: {eq: "lul/santa-icon.png"}) {
            childImageSharp {
                    fluid(maxWidth: 330) {
                        ...GatsbyImageSharpFluid_noBase64
                        ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        }
        packages2: file(relativePath: {eq: "lul/reindeer-icon.png"}) {
            childImageSharp {
                    fluid(maxWidth: 330) {
                        ...GatsbyImageSharpFluid_noBase64
                        ...GatsbyImageSharpFluidLimitPresentationSize
                }
            }
        } 
    }
    `)

    return (
        <div>
            <section class="hero is-medium">
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-two-fifths">
                                <p class="title is-size-2 is-size-3-touch has-text-weight-bold">Personalized Virtual Santa Message $49.99</p>
                                &nbsp;
                                <p className="subtitle is-size-5 is-uppercase has-text-weight-bold">OUR SIGNATURE EXPERIENCE</p>
                                <p className="is-size-5" style={{ marginBottom: "2rem" }}>The most entertaining virtual experience with Santa from the safety and comfort of your home! Fill out our questionnaire so that all messages are personalized just for you and your family.</p>
                                {/* <Virtual /> */}
                                <ModalVirtual />
                            </div>
                            <div className="column is-two-fifths">
                                <Img fluid={data.packages1.childImageSharp.fluid} alt="Santa Ring Featured Image" className="featured" style= {{ margin: "0 auto" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container"><hr></hr></div>
            <section class="hero is-medium">
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-two-fifths">
                                <p class="title is-size-2 is-size-3-touch has-text-weight-bold">Pre-Recorded Santa Reading $39.99</p>
                                &nbsp;
                                <p className="subtitle is-size-5 is-uppercase has-text-weight-bold">Santa will record a personalized story message for your child and send it directly to your inbox. You choose the perfect time to share the video with your family.</p>
                                <p className="is-size-5" style={{ marginBottom: "2rem" }}>
                                    <ul className="santa-list">
                                        <li>Night Before Christmas</li>
                                        <li>Rudolf the Red Nose Reindeer</li>
                                        <li>The Polar Express</li>
                                    </ul>
                                </p>
                                {/* <Recorded /> */}
                                <ModalRecorded />
                            </div>
                            <div className="column is-two-fifths">
                                <Img fluid={data.packages2.childImageSharp.fluid} alt="Santa Ring Featured Image" className="featured" style= {{ margin: "0 auto" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Packages