import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout/child";
import config from "../../data/SiteConfig";
import Packages from "../components/Packages/Packages"

class PackagesPage extends Component {
  render() {
    return (
      <Layout>
        <div className="home-container">
          <Helmet title={`Packages | ${config.siteTitle}`} />
          <div className="body-container">
            <Packages />
            <section className="hero is-small is-white has-background">
                <div className="hero-body">
                    <div className="container">
                    </div>
                </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PackagesPage;
